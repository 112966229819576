<template>
<v-list-item
  @click='$emit("selected", client)'
  :key="client.id">

  <template v-if='selectable' >
    <v-list-item-action  >
      <v-icon v-if='selected === false'
        @click='selected=true' >mdi-checkbox-blank-outline</v-icon>
      <v-icon v-else
        @click='selected=false' >mdi-check-box-outline</v-icon>
    </v-list-item-action>
  </template>

  <v-list-item-content>
    <v-list-item-title >
      <slot name='after-before'></slot>
      {{ fullName }}
      <slot name='after-title'></slot>
    </v-list-item-title>
    <slot name='subtitle' >
    <v-list-item-subtitle >
      <v-chip outlined x-small
        v-if='client.phone_number'
        color='primary' class='mr-2' >
        <v-icon small left
          class='hidden-xs-only' >mdi-phone</v-icon>
        {{client.phone_number}}
      </v-chip>
      <v-chip outlined x-small
        v-if='isRealEmail(client.email)'
        color='primary' >
        <v-icon left small class='hidden-xs-only' >mdi-email</v-icon>
        {{client.email}}
      </v-chip>
    </v-list-item-subtitle>
    <v-list-item-subtitle class='text-right' >
      <template v-if='spaces && spaces.length > 0' >
        <template v-for='space in spaces' >
          <v-chip v-if='space' x-small dark color='info' class='mr-1'
            :key='space.id' >
            <v-icon left x-small >mdi-share-variant</v-icon>
            {{space.title}}
          </v-chip>
        </template>
      </template>
    </v-list-item-subtitle>
    </slot>
  </v-list-item-content>

  <slot name="actions" >
  </slot>
  <v-list-item-action v-if='owner && owner != me.id' >
    {{owner.id}}
  </v-list-item-action>

  <!-- <v-list-item-action v-if='client.spaces' >
    {{spaces}}
  </v-list-item-action> -->

  <v-list-item-action v-if='withAction' >
    <slot name='action' >
      <v-btn @click='$emit("selected", client)' icon >
        <v-icon class="grey--text text--lighten-1">mdi-chevron-right</v-icon>
      </v-btn>
    </slot>
  </v-list-item-action>

</v-list-item>
</template>
<script type="text/javascript">
import {mapGetters} from 'vuex'

export default {
  name: 'RelationshipListItem',
  props: {
    item: { type: Object, default: () => { return {} } },
    selectable: { type: Boolean, default: false },
    withAction: { type: Boolean, default: true },
  },
  data () {
    return {
      selected: false
    }
  },
  methods: {
    initials (client) {
      let first = ''
      let last = ''
      if (client && client.first_name) {
        first = client.first_name.substr(0, 1)
      }
      if (client && client.last_name) {
        last = client.last_name.substr(0, 1)
      }
      return `${first}${last}`.toUpperCase()
    }
  },
  computed: {
    ...mapGetters('identity', ['getPractitionerById']),
    client () { return this.item },
    owner () {
      return this.client.practitioner
    },
    spaces () {
      let spaceIds = this.client.spaces
      if (spaceIds) {
        return spaceIds.map((s) => {
          let space = this.getSpaceById(s)
          if (space) { return space }
          return { id: s, title: s }
        })
      }
      return []
    },
    fullName () {
      let fname = this.client.first_name
      let lname = this.client.last_name
      if (!lname) { return fname }
      return `${fname} ${lname}`
    }
  },
  watch: {
    selected () {
      this.$emit('selected', this.selected)
    }
  }
}
</script>
<style >
.list__tile .chip .avatar,
.chip .list__tile__action {
  min-width: 30px;
}
.chip .icon {
  font-size: 15px;
}
</style>
