<template>
<v-card :dark='dark' tile >
  <v-card-title class='overline pb-0 mb-0' >
    Filter clients
  </v-card-title>
  <v-divider />
  <v-card-text >

    <v-card class='grey darken-3' >
      <v-subheader class='overline' >Ownership</v-subheader>
      <v-card-text >
        <v-radio-group v-model="sharing" class='mt-0 pt-0'>
          <v-radio dense label='All visible' value='all' />
          <v-radio dense label='Owned by me' value='me' />
        </v-radio-group>
        <!-- <v-select persistent-hint dense
          v-model='sharing'
          :items='spacesIOwn'
          item-text='title'
          item-id='id'
          label='Owned by space' class='mt-0 pt-0'
          hint='Only showing spaces for which you are an admin' /> -->
      </v-card-text>
    </v-card>

    <v-divider class='my-3' />
    <template v-if='facets && facets.spaces' >
      <v-subheader class='overline pa-0' >Spaces</v-subheader>
      <facet-field
        v-model='selectedSpaces'
        display-field='title' type='checkbox'
        :lookup-function='getSpaceById'
        :facets='facets.spaces'
        :x-small='false' />
    </template>

    <template v-if='sharing !== "me" && facets && facets.practitioner' >
      <v-subheader class='overline pa-0' >Practitioner</v-subheader>
      <facet-field
        v-model='selectedPractitioners'
        display-field='full_name' type='checkbox'
        :lookup-function='getPractitionerById'
        :facets='facets.practitioner'
        :x-small='false' />
    </template>

  </v-card-text>
  <!-- <pre v-text='computedParams' /> -->
  <v-divider />
  <v-card-actions >
    <v-btn @click='$emit("refresh")' block >
      Apply
    </v-btn>
  </v-card-actions>
</v-card>
</template>
<script>
import {mapGetters} from 'vuex'
import FacetField from '@/components/filters/FacetField.vue'

export default {
  name: 'ClientFilter',
  components: {FacetField},
  props: {
    dark: { type: Boolean, default: true },
    params : { type: Object, required: false, default: () => { return {} } },
    facets : { type: Object, required: false, default: () => { return {} } },
    clients : { type: Array, required: false, default: () => { return [] } }
  },
  data () {
    return {
      sharing: "all",
      selectedSpaces: [],
      selectedPractitioners: []
    }
  },
  methods: {
    apply () {
      this.$emit('params:update', this.computedParams)
    }
  },
  computed: {
    ...mapGetters('identity', ['spacesIOwn', 'getSpaceById', 'getPractitionerById', 'meId']),
    computedParams () {
      let params = this.params

      delete params['practitioner_ids']
      delete params['space_ids']

      if (this.selectedPractitioners && this.selectedPractitioners.length > 0) {
        params['practitioner_ids'] = this.selectedPractitioners.join(',')
      }
      if (this.selectedSpaces && this.selectedSpaces.length > 0) {
        params['space_ids'] = this.selectedSpaces.join(',')
      }

      if (this.sharing === 'me') {
        params['practitioner'] = this.meId
      } else {
        delete params['practitioner']
      }
      return params
    }
  }
}
</script>
