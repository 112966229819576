<template>
<query-page
  title='Clients'
  icon='mdi-account-circle-outline'
  :request='req'
  :page.sync='page'
  :pages='pages'
  @refresh='get' >

  <client-filter
    @refresh='get'
    :params.sync='params'
    :facets='facets'
    slot='filters' />

  <template slot='data' >
    <v-card tile flat >
      <v-system-bar class='caption' >
        <v-spacer />
        Showing {{relationships.length}} of {{count}} clients
      </v-system-bar>
      <v-list dense class='pa-0 elevation-0' >
        <template v-for='relationship in relationships' >
          <!-- <div :key='relationship.id'>{{relationship.id}}</div> -->
          <relationship-list-item
            :selectable='false'
            :item="relationship"
            :key="relationship.id"
            :with-action='false' />
          <v-divider :key='`${relationship.id}-div`' />
        </template>
      </v-list>
    </v-card>
    <!-- <pre v-text='relationships' /> -->
  </template>
</query-page>
</template>
<script>
import QueryPage from '@/components/layouts/QueryPage.vue'
import ClientFilter from '@/components/filters/ClientFilter.vue'
import RelationshipListItem from '@/components/listitems/RelationshipListItem.vue'

export default {
  name: 'ClientsPage',
  components: {
    QueryPage,
    ClientFilter,
    RelationshipListItem
  },
  data () {
    return {
      page: 1,
      req: {
        resource: 'ag.spaces.relationships'
      },
      params: {
        facets: 'spaces,practitioner'
      }
    }
  },
  mounted () {
    this.get()
  },
  watch: {
    params () { this.get() },
    page () {
      this.params.page = this.page
      this.get()
    }
  },
  computed: {
    relationships () {
      return this.getObjectPath(this.req, 'result.results', [])
    },
    facets () {
      return this.getObjectPath(this.req, 'result.facets', {})
    },
    pages () {
      return this.getObjectPath(this.req, 'result.pages', 1)
    },
    count () {
      return this.getObjectPath(this.req, 'result.count', 1)
    }
  },
  methods: {
    get () {
      this.req.params = this.params
      this.$request('get', this.req)
    }
  }
}
</script>
