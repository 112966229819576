<template>
<div>
  <!-- if less than x else: select -->
  <v-subheader v-if='title' class='overline' v-text=title />
  <v-chip-group v-if='type==="chip"' v-model='selected' column multiple >
    <template v-for="(count, value) in facets" >
      <v-chip :x-small='xSmall' filter v-if='value' :key="value" :value='value' clearable label >
        <template v-if='lookupFunction && lookupFunction(value)' >
          {{ lookupFunction(value)[displayField] }}
        </template>
        <template v-else >{{value}}</template>

        <v-avatar
          v-if='xSmall'
          style='height:14px!important;width:14px!important;min-width:14px!important;font-size:8px;'
          right color='teal' >{{ count }}</v-avatar>
        <v-avatar v-else
          right color='teal' >{{ count }}</v-avatar>
      </v-chip>
    </template>
  </v-chip-group>

  <template
    v-if='type==="checkbox"' >
    <template v-for="(count, value) in facets" >
      <v-checkbox dense hide-details class='ma-0 pa-0'
        v-if='count > 0'
        :key='value'
        v-model='selected' :value='value'
        :label='getLabel(value, count)' />
    </template>
  </template>
  <!-- <pre v-text='selected' /> -->
</div>
</template>
<script>
export default {
  name: 'FacetGroup',
  props: {
    title: { type: String, required: false },
    value: { type: Array, default: () => { return [] } },
    facets: { type: Object, required: true },
    lookupFunction: { type: Function, required: false },
    displayField: { type: String },
    type: { type: String, options: [], default: 'chip' },
    xSmall: { type: Boolean, default: true }
  },
  data () {
    return {
      selected: []
    }
  },
  computed: {
    hasItemList () {
      return (this.items && this.items.length > 0)
    }
  },
  methods: {
    getLabel (value, count) {
      if (this.lookupFunction) {
        let obj = this.lookupFunction(value)
        if (obj) {
          let title = obj[this.displayField]
          return `${title} (${count})`
        }
      }
      return `${value} (${count})`
    }
  },
  watch: {
    selected () {
      this.$emit('input', this.selected)
    }
  }
}
</script>